import { Getter } from "vuex";
import { IState } from './state';


const title: Getter<IState, IState> = (state) => state.puff.title;
const text: Getter<IState, IState> = (state) => state.puff.text;
const isLeftTextPosition: Getter<IState, IState> = (state) => state.puff.isLeftTextPosition;
const bgColor: Getter<IState, IState> = (state) => state.puff.bgColor;
const textColor: Getter<IState, IState> = (state) => state.puff.textColor;
const hasLink: Getter<IState, IState> = (state) => state.puff.hasLink;
const linkUrl: Getter<IState, IState> = (state) => state.puff.linkUrl;
const linkRel: Getter<IState, IState> = (state) => state.puff.linkRel;
const linkText: Getter<IState, IState> = (state) => state.puff.linkText;
const linkBgColor: Getter<IState, IState> = (state) => state.puff.linkBgColor;
const linkTextColor: Getter<IState, IState> = (state) => state.puff.linkTextColor;
const countOfImages: Getter<IState, IState> = (state) => state.puff.countOfImages;
const image1Url: Getter<IState, IState> = (state) => state.puff.image1Url;
const image1Size: Getter<IState, IState> = (state) => state.puff.image1Size;
const image2Url: Getter<IState, IState> = (state) => state.puff.image2Url;
const image2Size: Getter<IState, IState> = (state) => state.puff.image2Size;

export default {
	title,
	text,
	isLeftTextPosition,
	bgColor,
	textColor,
	hasLink,
	linkUrl,
	linkRel,
	linkText,
	linkBgColor,
	linkTextColor,
	countOfImages,
	image1Url,
	image1Size,
	image2Url,
	image2Size
}
