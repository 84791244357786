<template>
	<puff />
</template>

<script lang="ts">
import Vue from 'vue'
import Puff from './components/Puff.vue';

// Interfaces for component typechecking!
interface AppData {}
interface AppMethods {}
interface AppComputed {}
interface AppProps {}

export default Vue.extend<AppData, AppMethods, AppComputed, AppProps>({
	name: 'app',
	components: {
		Puff
	}
})
</script>
