<template>
	<div class="jk-page-puff-container sv-row" :class="puffClassName"
			:style="{background: bgColor, color: textColor}">
		<div class="jk-page-puff-container_text" :style="{width: textContainerWidth +'%'}">
			<div class="jk-page-puff-container_text-title">
				<h2 class="sv-h2" :style="{color: textColor}">{{title}}</h2>
            </div>
            <div class="jk-page-puff-container_text-text" v-if="text.length"><p>{{text}}</p></div>
			<div v-if="hasLink" class="jk-page-puff-container_text-link" >
				<a :href="puffLink.url" :rel="puffLink.rel" :title="puffLink.text">
					<div :style="{background: linkBgColor, color: linkTextColor + '!important'}">{{puffLink.text}}</div>	
				</a>
			</div>
		</div>
		<div v-if="countOfImages > 0" class="jk-page-puff-container-image_1"
			:style="{backgroundImage: 'url(' + image_1.url + ')', width: image_1.width +'%'}">
			<div class="arrow-right" v-if="isLeftTextPosition" :style="{borderLeft: '30px solid ' + bgColor}"></div>
			<div class="arrow-left"  v-if="!isLeftTextPosition" :style="{borderRight: '30px solid ' + bgColor}"></div>
		</div>
		<div v-if="countOfImages > 1" class="jk-page-puff-container-image_2"
			:style="{backgroundImage: 'url(' + image_2.url + ')', width: image_1.width +'%'}">
		</div>
	</div>
</template>

<script lang="ts">
import Vue from 'vue'

interface PuffImage {
	url: string,
	cssClass: string,
	width: number
}

interface PuffLink {
	url: string,
	rel:string,
	text: string
}

interface PuffProps {
	title: string,
	text: string,
	isLeftTextPosition: boolean,
	bgColor: string,
	textColor: string,
	hasLink: boolean,
	linkUrl: PuffLink,
	linkBgColor: string,
	linkTextColor: string,
	countOfImages: number,
	image_1: PuffImage,
	image_2: PuffImage,
	textContainerWidth: number,
	puffClassName: string
}

export default Vue.extend<{}, {}, {}, PuffProps>({
	name: 'Puff',
	computed: {
		title() { return this.$store.getters.title},
        text() { 
			var text:string = this.$store.getters.text;
			return (text.length === 0 || !text.trim())  ? '' : text;
		},
		isLeftTextPosition() { return this.$store.getters.isLeftTextPosition},
		bgColor() { return this.$store.getters.bgColor},
		textColor() { return this.$store.getters.textColor},
		linkBgColor() { return this.$store.getters.linkBgColor},
		linkTextColor() { return this.$store.getters.linkTextColor},
		hasLink(){ return this.$store.getters.hasLink},
		puffLink(){ 
			try{
				if(!this.$store.getters.hasLink)
					return null;

				var link: PuffLink = {
					url: this.$store.getters.linkUrl,
					rel: this.$store.getters.linkRel,
					text: this.$store.getters.linkText
				};
				return link;
			}
			catch(e)
			{
				console.log(e.message);
			}
		},
		countOfImages(){ return this.$store.getters.countOfImages},
		textContainerWidth(){
			try
			{
				var count = this.$store.getters.countOfImages;
				var textContainerSize = 12;
				var image_1_Size = count > 0 ? Number(this.$store.getters.image1Size) : 0;
				var image_2_Size = count > 1 ? Number(this.$store.getters.image2Size) : 0;				
				
				textContainerSize = 12 - image_1_Size - image_2_Size;
				var width = 100/12*textContainerSize;
				return width; 
			}
			catch(e)
			{
				console.log(e.message);
			}
		},
		image_1(){
			try{
				var count = this.$store.getters.countOfImages;
				if(count < 1)
					return null;
				var size = Number(this.$store.getters.image1Size);
				var width = 100/12*size;
				var img: PuffImage = {
						url: this.$store.getters.image1Url,
						cssClass: '',
						width: width
					};
				return img;	
			}
			catch(e)
			{
				console.log(e.message);
			}
		},
		image_2(){
			try{
				var count = this.$store.getters.countOfImages;
				if(count < 2)
					return null;

				var size = Number(this.$store.getters.image2Size);
				var width = 100/12*size;
				var img: PuffImage = {
						url: this.$store.getters.image2Url,
						cssClass: '',
						width:width
					};
				return img;
			}
			catch(e)
			{
				console.log(e.message);
			}	
		},
		puffClassName(){
			var countOfImages: number = this.$store.getters.countOfImages;
			if(countOfImages < 1)
				return 'jk-page-puff-container-without-image';

			if(!this.$store.getters.isLeftTextPosition)
				return 'jk-page-puff-container_left-img';
			
			return '';
		}
	}
})
</script>
