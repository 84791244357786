export interface IState {
	puff: IPuff
}

export interface IPuff {
	title: string,
	text: string,
	isLeftTextPosition: boolean,
	bgColor: string,
	textColor: string,	
	hasLink: boolean,
	linkUrl: string,
	linkRel: string,
	linkText: string,
	linkBgColor: string,
	linkTextColor: string,
	countOfImages: string,
	image1Url: string,	
	image1Size: string,
	image2Url: string,
	image2Size: string
};

const deafultState: IState = {
	puff: {
		title: '',
		text: '',
		isLeftTextPosition: true,
		bgColor: '',
		textColor: '',
		hasLink: false,
		linkUrl: '',
		linkRel: '',
		linkText: '',
		linkBgColor: '',
		linkTextColor: '',
		countOfImages: '',
		image1Url: '',
		image1Size: '',
		image2Url: '',
		image2Size: ''
	}
};

export default deafultState;
